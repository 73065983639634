<template>
  <div>
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-12 text-center mb-5">
        <Spinner v-if="report.status === 'preparing'">Generating report... This should only take a few minutes.</Spinner>
        <Spinner v-else-if="report.status === 'generating'">Generating report... This should only take a few minutes.</Spinner>

        <div v-else-if="report.status === 'ready'">
          <div class="font-w600 font-size-h4 mb-3"><i class="fa fa-check-circle text-success mr-2"></i> Report ready!</div>
          <p>{{ report.fileName }}</p>
          <a class="btn btn-primary btn-lg" :class="{ disabled: !downloadUrl }" :href="downloadUrl"><i class="fa fa-fw fa-download"></i> Download</a>
        </div>

        <div v-else-if="report.status === 'error'">
          <div class="font-w600 font-size-h4 mb-2"><i class="fa fa-times-circle text-danger"></i> Report failed to generate.</div>
          <div>Please contact support.</div>
        </div>
      </div>
      <!-- <div class="col-lg-4">
        <div class="alert alert-info">
          <h3 class="font-size-h5 mb-2"><i class="fad fa-hourglass mr-1"></i> Taking a while to generate?</h3>
          <p class="mb-0">You will get a notification when this report is ready to download, so you may safely navigate away from this page.</p>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Spinner from '@/components/SpinnerLogo';

export default {
  name: 'ReportDownload',
  components: {
    Spinner
  },
  data() {
    return {
      poll: {},
      fetchedDownloadUrl: false,
      downloadUrl: ''
    };
  },
  computed: {
    ...mapGetters({
      report: 'report/report',
      loadingAction: 'report/loadingAction'
    })
  },
  beforeDestroy() {
    this.endPolling();
    this.resetState();
  },
  async mounted() {
    const { id } = this.$route.params;
    const { regenerate } = this.$route.query;

    if (!id) {
      return this.$router.push({ name: 'reports' });
    }

    if (regenerate === 'true') {
      await this.regenerateReport({ id });
    }

    const report = await this.getReport({ id });

    if (report?.status === 'ready') {
      this.getDownloadUrl();
    } else {
      this.startPolling();
    }
  },
  methods: {
    ...mapActions({
      getReport: 'report/get',
      download: 'report/download',
      regenerateReport: 'report/regenerate'
    }),
    ...mapMutations({
      resetState: 'report/RESET_STATE'
    }),
    async getDownloadUrl() {
      if (!this.fetchedDownloadUrl) {
        this.fetchedDownloadUrl = true;

        const download = await this.download({ id: this.report._id });

        this.downloadUrl = download.downloadUrl;
      }
    },
    startPolling() {
      this.poll = setInterval(async () => {
        const report = await this.getReport({ id: this.$route.params.id });
        if (report.status === 'ready' || report.status === 'error') {
          this.endPolling();
          this.getDownloadUrl();
        }
      }, 5000);
    },
    endPolling() {
      clearInterval(this.poll);
      this.poll = null;
    }
  }
};
</script>
